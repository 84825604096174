import { FC, useMemo } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { MembersFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import useFetchClientUsers from '../../../../hooks/useFetchClientUsers';

const MembersFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const membersFilter = filter as MembersFilterValue | undefined;
  const { data: clientUsers = [] } = useFetchClientUsers();

  const membersFilterOptions = useMemo(
    () => clientUsers?.filter((user) => membersFilter?.includes(user.id)).map((user) => user.fullName),
    [clientUsers, membersFilter],
  );

  return (
    <div className="flex flex-wrap items-center gap-2">
      {membersFilterOptions?.map((member) => (
        <div key={member}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={`${member}`} />
        </div>
      ))}
    </div>
  );
};

export default MembersFilterOverview;
